import { LitElement, html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../customElement';

@customElement('character-limit')
export class CharacterLimit extends LitElement {
    static styles = css`
    .success, .fail {
        color: darkcyan;
    }

    .box {
        padding: 10px;
        width: 100%;
        text-align: right;
    }
    `;

    @property({ type: Number, attribute: 'limit' })
    limit = 0;

    @state()
    message: { class: string, message: string } | null = null;

    _slottedChildren() {
        const slot = this.shadowRoot.querySelector('slot');
        return slot.assignedElements({ flatten: true });
    }

    handleSlotchange(e) {
        const childNodes = e.target.assignedElements({ flatten: true });

        const perform = () => {
            const target = childNodes[0] as (HTMLTextAreaElement | HTMLInputElement);
            const charactersLeft = this.limit - target.value.length;

            if (charactersLeft >= 0) {
                this.message = { class: 'success', message: `${charactersLeft} characters left` };
                return;
            }
            target.value = target.value.substring(0, this.limit);
            this.message = { class: 'fail', message: `${charactersLeft} characters left` };
        }

        childNodes[0].addEventListener('keyup', (e: KeyboardEvent) => {
            //Figure out how many characters are left
            perform();
        });

        perform();
    }

    render() {
        return html`
    <div>
    <slot @slotchange=${this.handleSlotchange}></slot>
        <div class="box ${(this.message?.class ?? "")}">${this.message?.message ?? ""}</div>
    </div>
      `;
    }
}