import { html, css, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../../customElement';
import { UserNote } from '../userNotes/UserNote';


@customElement("drop-point")
export class DropPoint extends LitElement {
	static styles = css`

`;
	@property()
	public itemHover: boolean = false;

	drop(ev: DragEvent) {
		ev.dataTransfer.dropEffect = 'move';
		let html = ev.dataTransfer.getData("text/html");
		
		//Create a new drop point
		let newDropPoint = document.createElement('drop-point');
		newDropPoint.innerHTML = html;
		let snippet: any = $(newDropPoint);
		snippet.snippet('initialize');
		let oldNode = document.getElementById(newDropPoint.children[0].id);

		//If we are dragging this onto itself we need to cancel everything
		if (this.id === oldNode.id) {
			return;
		}

		//We now need to insert the new drop point into the right place.
		//
		//If the item is going "up" the list then...
		let indexOfThis: number = Array.prototype.indexOf.call(this.parentElement.children, this);
		let indexOfOldNode: number = Array.prototype.indexOf.call(this.parentElement.children, oldNode.parentElement);

		let movingDown = indexOfOldNode < indexOfThis;
		if (movingDown) {
			this.after(newDropPoint);
		} else {
			this.before(newDropPoint);
		}

		//We're going to need to remove the original html from the DOM tree. Ther contents of divContainer will be used instead
		oldNode.parentElement.remove();
	}

	dragEnter(ev: DragEvent) {
		
	}

	dragLeave(ev: DragEvent) {
		
	}
	dragEnd(ev: DragEvent) {
		this.dispatchEvent(new CustomEvent("dragcompleted", { composed: true, bubbles: true, cancelable: true }));
	}

	connectedCallback() {
		super.connectedCallback();
		this.ondrop = (ev: DragEvent) => { this.drop(ev); };
		this.ondragover = (ev: DragEvent) => ev.preventDefault();
		this.ondragend = this.dragEnd;

		this.ondragenter = this.dragEnter;
		this.ondragleave = this.dragLeave;
	}


	render() {
		return html`
<slot></slot>
`;
	}
}