import { LitElement, html, css} from 'lit';
import { property } from 'lit/decorators.js';
import { customElement } from '../../customElement';

@customElement('tab-content')
export class Tab extends LitElement {
    @property()
    selected: boolean = false;

    @property()
    title: string = '';

    render() {
        return html`
    <div style="${this.selected ? '' : 'display: none'};"><slot></slot></div>
`;
    }
}