import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LitElement, css, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../../customElement';
import { css as faCSS } from '../../icons/fontAwesome';

@customElement('user-note')
export class UserNote extends LitElement {
    static styles = [css`
.note-item {
    list-style-type: none;
    /*padding: 10px 5px;*/
    margin: 5px 0px;
    width: 100%;
    
    display: flex;
    border: 1px solid darkcyan;
}


.note-background {
    background-color: #ffffff;
}
.flex-grow {
    flex-grow: 1;
}

button {
    padding: 8px 12px;
    background-color: darkcyan;
    color: white;
    width: 41px;
    height: 41px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.danger, .deleting {
    background-color: #ba1818;
    border-color: #ba1818;
}
`,
        faCSS
    ];

    @property({attribute: "user-note-id"})
    userNoteId: string;

    @state()
    isDeleting: boolean = false;

    deleteClicked(e: MouseEvent) {
        (async () => {
            this.isDeleting = true;
            //Send delete request to the server

            //Upon receipt of succesful deletion, send a delete event, so the list can be updated
            let ev = new CustomEvent("deleteRequested", { bubbles: true, cancelable: true, composed: true, detail: this.userNoteId })
            this.dispatchEvent(ev);
        })();
    }

    editClicked() {
        this.dispatchEvent(new CustomEvent("editRequested", { bubbles: true, cancelable: true, composed: true, detail: this.userNoteId }));
    }

    render() {
        return html`
<div style="display: flex;" class="note-item">
    <div class="flex-grow ${this.isDeleting ? 'deleting' : 'note-background'}" style="padding: 10px 5px; word-break: break-all; hyphens: auto;">
        <slot></slot>
    </div>
    <div style="display: flex; flex-direction: column;">
        <div style="padding:5px 5px 0px 5px;">
            <button @click="${(e: MouseEvent) => this.editClicked()}">
                <i class="fas fa-edit"></i>
            </button>
        </div>

        <div style="padding:5px 5px 5px 5px;">
            <div style="background-color: white; color: white; border 1px solid darkcyan; border-radius: 5px;">
                ${
            !this.isDeleting ?
                html`<button class="danger" @click="${this.deleteClicked}">
                        <i class="fas fa-trash"></i>
                     </button>` : html`
                            <button class="danger" @click="${this.deleteClicked}" disabled>
                        <i class="fas fa-trash"></i>
                     </button>
                            `
                }
            </div>
        </div>
    </div>
</div>
`;
    }
}