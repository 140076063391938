import { LitElement, html, css } from 'lit';
import { customElement } from '../customElement';

@customElement("perfect-square")
export class PerfectSquare extends LitElement {
	static styles = css`
#container {
    display: inline-block;
    position: relative;
    width: 100%;
}
#container:after {
    content: '';
    display: block;
    margin-top: 100%;
}
#element {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
`;

	render() {
		return html`
<div id="container">
	<div id="element">
		<slot></slot>
	</div>
</div>
`;
	}
}