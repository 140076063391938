import { html, css, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../../customElement';
import moveIcon from './MoveIcon.png';

@customElement("draggable-item")
export class DraggableItem extends LitElement {

	dragStart(e: DragEvent) {
		this.dispatchEvent(new CustomEvent('dragstarting', { cancelable: false, bubbles: true, composed: true, detail: this }));
		e.dataTransfer.setData("text/html", this.outerHTML);
		e.dataTransfer.dropEffect = 'move';
		e.dataTransfer.setDragImage(this, 0, 0);
	}

	connectedCallback() {
		super.connectedCallback();
	}

	render() {
		return html`
<div style="display: flex; flex-direction: column;">
	<div style="width: 20px; display: none;" draggable @dragstart="${this.dragStart}">
		<div style="width: 100%; height: 20px; background-image: url('${moveIcon}'); background-size: contain;">
		</div>
		
	</div>
	<div style="width: 100%;">
		<slot></slot>
	</div>
</div>`;
	}
}