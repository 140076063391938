import { LitElement, css, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../../customElement';

import { Tab } from './tab';

@customElement('tab-pane')
export class TabPane extends LitElement {
    static styles = css`
    .tab {
        background-color: white;
        color: black;
        padding: 10px;
        border: solid lightgrey 1px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: none;
        cursor: pointer;
        display: inline-block;
    }
    .tab.selected {
        color: white;
        background-color: darkcyan;
        border-color: darkcyan;
    }
    .pane {
        border: 1px solid lightgrey;
        padding: 10px;
    }
`;

    @property() width: string = '100%';
    @state() private tabs: Array<Tab> = [];
    @state() private selectedIndex: number = 0;

    slotChanged(e: Event) {
        let slot = this.shadowRoot.querySelector('slot');
        this.tabs = slot.assignedElements() as Array<Tab>;
    }

    tabSelected(index: number) {
        let counter = -1;
        for (let tab of this.tabs) {
            counter++;

        }
    }

    render() {
        return html`
<div style="width: ${this.width};">
    <div>
        <nav>
            ${this.tabs.map((tab, index) => {
            let isSelected = index === this.selectedIndex;
            let clickEvent = (e: Event) => {
                this.selectedIndex = index;
            };

            tab.selected = isSelected;

            return html`<div class="tab ${isSelected ? 'selected' : ''}" @click="${clickEvent}">${tab.title}</div>`;
        })}
        </nav>
    </div>
    <div class="pane">
        <slot @slotchange="${this.slotChanged}"></slot>
    </div>
</div>
`;
    }
}