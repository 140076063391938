import { LitElement, html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../../customElement';
import { css as fa } from '../../icons/fontAwesome';

@customElement("notification-list")
export class NotificationList extends LitElement {
	static styles = css`${fa} 
	.secondary-color{ color: red; }
	.gold{ color: gold; }`;

	@property({ type: Boolean, attribute: "new-notifications" })
	hasNewNotifications: boolean = false;

	@property()
	userid: string;

	createRenderRoot() {
		return this;
	}

	render() {
		let icon = this.hasNewNotifications ? html`<i class="icon fas fa-bell your2050-gold"></i>` : html`<i class="icon far fa-bell secondary-color"></i>`;
		return html`${icon}`;
	}
}