import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map';
import { customElement } from '../customElement';

@customElement('floating-panel')
export class FloatingPanel extends LitElement {
    static styles = css`
.panel {
    max-height: calc(100vh - 180px);
    width: 500px;
}
.panel.on{
    border: 1px solid black;
    background-color: white;
}
.panel.off {
    display: none;
}

@media(max-width: 768px) {
    .panel {
        width: calc(100vw - 50px);
    }
}
`;

    @property() height: number;
    @property() width: number;
    @property() show: boolean = false;
    @property({ type: Boolean }) scrollable: boolean = false;

    toggleOpen() {
        this.show = !this.show;
    }

    render() {

        let scrollableStyle = {};

        if (this.scrollable) {
            scrollableStyle = {overflowY: 'auto'};
        }

        let style = styleMap({
            //width: `${this.width}px`,
            //height: `${this.height}px`,
            ...scrollableStyle
        });

        return html`
    <div style=${style} class="panel ${this.show ? 'on' : 'off'}">
        <slot></slot>
    </div>
`;
    }
}