import { html, css, LitElement } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { customElement } from '../customElement';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { css as faCSS } from '../icons/fontAwesome';

@customElement('note-editor')
export class UserNoteEditor extends LitElement {
	static styles = css`
.notes-form-container {
	display: flex;
	flex-direction: column;
}
${faCSS}`;

	private editor: ClassicEditor = null;

	@property({ type: Boolean, attribute: 'iscreate' })
	isCreate: boolean = false;

	@property({ attribute: 'user-note-id' })
	userNoteId: string = '';

	@property({type: String, attribute: 'notetext'})
	noteText: string = '';

	@state()
	updatedNoteText: string;

	@query('textarea')
	noteTextArea: HTMLTextAreaElement;

	createRenderRoot() {
		return this;
	}

	async disable() {
		await this.editor.destroy();
		this.editor = null;
	}

	attributeChangedCallback(name: string, _old: string, value: string) {
		super.attributeChangedCallback(name, _old, value);
		
		if (name === 'notetext') {
			this.updatedNoteText = value;
		}
	}

	firstUpdated(_changedProperties: Map<string | number | symbol, unknown>) {
		super.firstUpdated(_changedProperties);
		(async () => {
			let ed = await ClassicEditor.create(this.noteTextArea, {
				toolbar: ['heading', 'paragraphs', 'bold', 'italic', 'undo', 'redo', 'numberedList', 'bulletedList', 'blockQuote']
			});
			this.editor = ed;
			
		})();
	}


	disconnectedCallback() {
		super.disconnectedCallback();
		this.editor.destroy();
		this.editor = null;
	}

	submitNote(ev: MouseEvent) {
		this.updatedNoteText = this.editor.getData();
	}

	cancelEdit(e: MouseEvent) {
		//Restore to original note text
		this.editor.setData(this.noteText);
		this.dispatchEvent(new CustomEvent("editCancelled", { bubbles: true, cancelable: true, composed: true, detail: this }));
	}

	render() {
		return html`
${this.isCreate ? null : html`<input type="hidden" name="id" value=${this.userNoteId} />`}
<div class="notes-form-container snippet">
	<div style="flex-grow: 1;">
		<div class="hells-bells">
			<textarea name="noteText">${this.updatedNoteText}</textarea>
		</div>
	</div>
	<div style="flex-grow: 0;">
		<div style="display: flex;">

		${this.isCreate ? null : html`
				<button @click="${this.cancelEdit}" class="danger btn btn-danger" style="flex-grow: 1; margin-right: 5px;" type="button"><i class="fas fa-window-close"></i></button>
`}
		<button id="submit-note-button" class="btn btn-your2050" type="submit" @click="${this.submitNote}">
				<i class="icon fas fa-paper-plane"></i>
			</button>
		</div>
	</div>
</div>
`;
	}
}
