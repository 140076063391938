import { LitElement, html } from 'lit';
import { customElement } from '../customElement';
import { property } from 'lit/decorators.js';
import { Proposal } from '../models/proposal';


@customElement('proposal-tile')
export class ProposalTile extends LitElement {
    @property({ attribute: 'proposal' })
    public proposal: Proposal;

    @property({attribute: 'discuss'})
    public discussIconClassName: string;

    @property({attribute: 'edit'})
    public editIconClassName: string;

    override createRenderRoot() {
        return this;
    }

    override render() {
        let ammendmentsdetail = html`${this.proposal.ammendmentCount > 0 ? html`<div class="proposal-tile-amendments">
                        <div style="height:100%; position:absolute; right:35px;">
                            <!--Show icon-->
                            <div name="icon">
                                <i class="icon ${this.editIconClassName}"></i>
                            </div>

                            <!--Show count-->
                            <div name="label">
                                ${this.proposal.ammendmentCount}<!--Ammendment count-->
                            </div>

                            <!--Weird bars under the icon-->
                            <div name="amendment-bars">
                                <div name="left">
                                </div>
                                <div name="right"></div>
                            </div>
                        </div>

                    </div>` : html``}`;

        let colorClass = this.proposal.isPublicCommunity ? "tile-blue-text" : "";
        return html`
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 snippet-same-height snippet proposal-tile">
            <div class="word-wrap">
                <h3 style="font-family: Montserrat, Arial, Helvetica, sans-serif"><strong>${this.proposal.headline}</strong></h3>
                <p class="tileHighlight ${colorClass}">
                    ${this.proposal.closed ? html`<span><strong style="color: black; font-family: Merriweather, Georgia, serif">Closed</strong></span>` : html`Time remaining: <strong>${this.proposal.timeRemaining}</strong>`}
                    <span style="float: right;"><i class="icon ${this.discussIconClassName}"></i></span>
                </p>
                ${this.proposal.isOwner && this.proposal.ammendmentCount > 0 && !this.proposal.closed ? ammendmentsdetail : html``
    }
                ${!!this.proposal.start ? html`<p>Start: ${this.proposal.start}</p>` : html``}
                ${!!this.proposal.end ? html`<p>End: ${this.proposal.end}</p>` : html``}
                ${this.proposal.isOwner ? html`<div style="width:100%; height:13px; position:relative; display: flex;">
                <div style="display:inline-block;" class="secondary-color ${colorClass}">
                    <strong style="font-weight: 900;">My Proposal</strong>
                </div>
            </div>` : html``}
            </div>
        </div>
        `;
    }
}