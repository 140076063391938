import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { ToggledEvent } from './ToggleEvent';
import { customElement } from '../../customElement';

@customElement('toggle-switch')
export class ToggleSwitch extends LitElement {
    static styles = css`
.toggle-switch{
    width: 46px; padding: 2px;
    background-color: grey;
    display: flex;
    border-radius: 12px;
    cursor: pointer;
}
.toggle-switch.on {
    background-color: darkcyan;
    line-height: 1.42857143;
}

.toggle-switch.on-blue {
    background-color: #0d8bd4;
}

.toggle {
    width: 18px; height: 18px; background-color: white;
    flex-grow: 0;
    border-radius: 9px;
}

.label {
    flex-grow: 1;
    color: white;
    height: 16px;
}
`;

    constructor() {
        super();
    }

    @property({ type: Boolean, attribute: "on" })
    on: boolean;

    @property() name: string;

    label: { on: string, off: string } = { on: "Yes", off: "No" };

    @property({ type: Boolean })
    blueTheme: boolean = false;

    async toggle() {
        this.on = !this.on;
        await this.updateComplete;

        let options = {
            detail: this.on,
            bubbles: true,
            composed: true
        };
        this.dispatchEvent(new ToggledEvent(this.on));

        //this.dispatchEvent(new CustomEvent("toggled", options));
    }

    render() {
        let on = html`<div class="toggle" ${this.name ? html`name="${this.name}"` : html``}></div><div class="label">${this.label.on}</div>`;
        let off = html`<div class="label">${this.label.off}</div><div class="toggle"></div>`;

        return html`<div class="toggle-switch ${this.on ? (!this.blueTheme ? "on" : "on-blue") : ""} " @click=${(e) => this.toggle()}>${this.on ? on : off}</div>`;
    }
}