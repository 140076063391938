import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { ToggledEvent } from './ToggleEvent';
import { customElement } from '../../customElement';

@customElement("toggle-switch-input")
export class ToggleSwitchInput extends LitElement {

	override createRenderRoot() {
		return this;
	}

	@property({ attribute: "name" })
	public name: string = "";

	@property({ attribute: "on", type: Boolean })
	public on: boolean = false;

	@property({attribute: true, type: Boolean})
	public center: boolean = false;

	public toggleChanged(e: ToggledEvent) {
		this.on = e.toggledState;
	}

	render() {
		return html`
		<div style="display: inline-block; margin: 0 auto;">
${this.on ? html`<toggle-switch on @toggled=${this.toggleChanged}/>` : html`<toggle-switch @toggled=${this.toggleChanged}/>`}
<input name="${this.name}" value="${(this.on ? "True" : "False")}" type="hidden" />
</div>
`;
	}
}