import { LitElement, html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../customElement';

@customElement('content-switcher')
export class ContentSwitcher extends LitElement {
    static styles = css`
.hidden {
    display: none;
}
`;
    @state()
    showMainContent: boolean = true;

    switch() {
        this.showMainContent = !this.showMainContent;
    }

    render() {
        return html`
<div class="${this.showMainContent ? '' : 'hidden'}">
    <slot name="main-content"></slot>
</div>
<div class="${this.showMainContent ? 'hidden' : ''}">
    <slot name="secondary-content"></slot>
</div>
`;
    }
}