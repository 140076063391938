import { LitElement, html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { customElement } from '../customElement';

@customElement('modal-dialog')
export class ModalDialog extends LitElement {
	static styles = css`
.modal-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 10;
}

.modal {
	width: 600px;
	margin: 30px auto;
	background-color: white;
	max-height: calc(100vh - 30px);
	overflow-y: auto;
}

@media(max-width: 767px) {
	.modal {
		width: 100%;
	}
}

.modal .heading {
	border-bottom: 1px solid black;
	padding: 20px;
}

.modal .heading h2 {
	margin: 0px;
}	

.modal .body {
	padding: 20px;
}

.modal .footer {
	display: flex;
	padding: 20px;
}

.modal .footer .white-space {
	width:100%;
}

.btn {
	padding: 8px 12px;
	border: none;
	font-weight: 600;
	color: white;
	border-radius: 15px;
	text-align: center;
	cursor: pointer;
}

.btn-primary {
	background-color: #8d8d8d;
}

.buttons {
	display: flex;
}

.buttons button {
	margin-right: 10px;
}
`;

	@property({type: Boolean})
	invisible: boolean = false;

	@property()
	primaryColor: string = 'darkcyan';

	@property()
	heading: string | null = null;

	@state()
	showHtmlHeading: boolean = false;

	@property({ type: String, attribute: 'save-text' })
	saveText: string | null = null;

	@property()
	cancelText: string = 'Close';

	close(event: MouseEvent) {
		event.stopPropagation();
		this.dispatchEvent(new CustomEvent('secondarybuttonclicked', { bubbles: true, cancelable: false, composed: true, detail: this }));
		this.invisible = true;
	}

	save(event: MouseEvent) {
		this.dispatchEvent(new CustomEvent('primarybuttonclicked', { bubbles: true, cancelable: false, composed: true, detail: this }));
		this.invisible = true;
	}

	headingSlotChanged(ev: Event) {
		this.showHtmlHeading = true;
	}

	render() {
		if (!this.invisible) {
			return html`
<div class="modal-container"><!-- Takes over the whole screen -->
	<div class="modal"><!-- The actual div that will be shown -->
		${this.heading ? html`<div class="heading">
			<h2>${this.heading}</h2>
		</div>` : html`<div class="heading" style="${!this.showHtmlHeading ? "display: none;" : ""}"><slot name="heading" @slotchange="${this.headingSlotChanged}"></slot></div>`}
		<div class="body">
			<slot></slot>
		</div>
		<div class="footer">
			<div class="white-space"></div>
			<div class="buttons">
				<button @click=${this.close} type="button" class="btn btn-primary">
					${this.cancelText ?? 'Close'}
				</button>
				${this.saveText !== null ? html`<button type="button" @click=${this.save} class="btn btn-save" style="background-color: ${this.primaryColor}">${this.saveText}</button>` : null}
				
			</div>
		</div>
	</div>
</div>
`;
		}

		return html``;
	}
}